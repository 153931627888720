import classNames from "classnames";
import StaffSignupUploadImage from "./ staff-signup-upload-image";
import StaffSignupColorPicker from "./staff-signup-color-picker";
import StaffSignupInput from "./staff-signup-input";

export const generateFieldList = (fieldList, formik) => {
  return fieldList.map((field) => {
    switch (field.inputType) {
      case "image":
        return (
          <div
            className={classNames("add-staff__field-container", {
              "professional-photo": field.types?.[0].title.includes(
                "professional"
              ),
            })}
          >
            {field?.types?.map((fieldType) => (
              <StaffSignupUploadImage
                params={formik.values}
                handleUpdateParams={formik.setFieldValue}
                key={fieldType.title}
                type={fieldType.type}
                errors={formik.errors}
                title={fieldType.title}
                isLightLogo={fieldType.title.includes("Light")}
                aspect={fieldType.aspect}
              />
            ))}
          </div>
        );
      case "colorPicker":
        return (
          <StaffSignupColorPicker
            params={formik.values}
            handleUpdateParams={formik.setFieldValue}
            key={field.title}
            type={field.type}
            title={field.title}
          />
        );
      case "domainName":
        return (
          <StaffSignupInput
            onBlur={formik.handleBlur}
            key={field.title}
            handleUpdateParams={formik.handleChange}
            handleOnBlur={formik.handleBlur}
            title={field.title}
            type={field.type}
            fieldType={field.fieldType}
            params={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isSubDomainInput
          />
        );

      default:
        return (
          <StaffSignupInput
            onBlur={formik.handleBlur}
            key={field.title}
            handleUpdateParams={formik.handleChange}
            handleOnBlur={formik.handleBlur}
            title={field.title}
            type={field.type}
            fieldType={field.fieldType}
            params={formik.values}
            errors={formik.errors}
            touched={formik.touched}
          />
        );
    }
  });
};
