import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { useFormik, Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { navigate } from "gatsby";
import StaffRoleGroup from "../../features/signup/staff-sign-up-role-group.tsx";
import { staffRoleEnum } from "../../features/signup/staff-sign-up.enum.ts";
import StaffSignupForm from "../../features/signup/staff-signup-form";
import { useCreateCompanyAdmin } from "../../hooks/staff.hooks";
import { splitNameFromFullName } from "../../utils/user-data";
import CustomStepper from "../../features/signup/custom-stepper";
import PersonalDetails from "../../features/signup/steps/personal-details";
import CardInfo from "../../features/signup/steps/card-info";
import CompanyInfo from "../../features/signup/steps/company-info";
import BrandingInfo from "../../features/signup/steps/brand-info";
import { validateEmail, validatePhoneNumber } from "../../api-services/users";
import { validateCompanyInfo } from "../../api-services/company";
import SignupBanner from "../../components/signup-banner-left";
import CustomButton from "../../components/custom-button";
import SuccessStep from "../../features/signup/steps/success-step";
import { WealthxLogoDark } from "../../images";

const loadingSpinner = <CircularProgress color="inherit" size={24} />;

const validationSchema = [
  Yup.object({
    brokerName: Yup.string("Enter broker name").required("Name is required"),
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required")
      .test("broker-name-unique", "Email is already taken", async function (
        value
      ) {
        if (!value) return false;
        try {
          await validateEmail(value);
        } catch (error) {
          const {
            data: { message = "" },
          } = error;
          return this.createError({ message });
        }
        return true;
      }),
    phoneNumber: Yup.number("Enter broker Phone Number")
      .required("Phone Number is required")
      .test(
        "phone-number-unique",
        "Phone number is already taken",
        async function (value) {
          if (!value) return true;
          try {
            await validatePhoneNumber(value);
          } catch (error) {
            const {
              data: { message = "" },
            } = error;
            return this.createError({ message });
          }
          return true;
        }
      ),
  }),
  Yup.object({
    brokerName: Yup.string("Enter broker name").required("Name is required"),
  }),
  Yup.object({
    companyName: Yup.string("Enter company Name")
      .required("Company name is required")
      .test(
        "company-name-unique",
        "Company name is already taken",
        async function (value) {
          if (!value) return true;
          try {
            await validateCompanyInfo({ params: { value } });
          } catch (error) {
            const {
              data: { message = "" },
            } = error;
            return this.createError({ message });
          }
          return true;
        }
      ),
    companyDomain: Yup.string("Enter company domain")
      .required("Company domain is required")
      .test(
        "company-domain-unique",
        "Company domain is already taken",
        async function (value) {
          if (!value) return true;
          try {
            await validateCompanyInfo({ params: { value } });
          } catch (error) {
            const {
              data: { message = "" },
            } = error;
            return this.createError({ message });
          }
          return true;
        }
      ),
    connectiveAccountId: Yup.string("Enter broker Connective Id"),
    introductionVideoUrl: Yup.string("Enter Introduction Video URL"),
    msTeamHook: Yup.string("Enter Microsoft Team Hook id"),
    slackHook: Yup.string("Enter Slack Hook id"),
    activeCampaignContactListId: Yup.string("Enter Active Campaign List id"),
  }),
  Yup.object({
    calendlyUrl: Yup.string("Enter broker Calendly URL"),
    imageUrl: Yup.string("Enter professional photo").required(
      "Professional photo is required"
    ),
    companyLogoURL: Yup.string("Input company Logo").required(
      "company Logo is required"
    ),
    companyLogoDarkURL: Yup.string("Input company Logo").required(
      "company Logo is required"
    ),
  }),
];

const steps = [PersonalDetails, CardInfo, CompanyInfo, BrandingInfo];

const getPathParam = (index) => {
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  return pathSegments[index] || null;
};

const Signup = () => {
  const planCode = getPathParam(1);
  const [staffRole, setStaffRole] = useState(staffRoleEnum.BROKER);
  const createCompanyAdmin = useCreateCompanyAdmin();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const ServicesRef = useRef(null);
  const [step, setStep] = useState(0);
  const [cardElement, setCardElement] = useState(null);
  const [confirmedEmail, setConfirmedEmail] = useState(null);
  const cardRef = useRef(null);
  const handStripeElement = (info) => {
    setCardElement(info);
  };
  const handleNextStep = async (formik) => {
    if (step === 1 && !cardElement) {
      return;
    }
    const errs = await formik.validateForm();
    if (Object.keys(errs).length > 0) {
      return;
    }
    if (step === 0) {
      setConfirmedEmail(formik.values.email);
    }
    setStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setStep((prev) => prev - 1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleSetStaffRole = (role) => {
    setStaffRole(role);
  };

  const handleClose = () => {
    setOpen(false);
    setIsSuccess(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCreateBroker = async (brokerDetailParams) => {
    createCompanyAdmin.mutate(brokerDetailParams, {
      onSuccess: () => {
        setStep(4);
      },
      onError: (errors) => {
        const {
          data: { message },
        } = errors;

        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      businessPhoneNumber: "",
      customerSupportEmail: "",
      companyNumber: "",
      brokerName: "",
      financialServicesLicenseNumber: "",
      creditLicenseNumber: "",
      phoneNumber: "",
      connectiveAccountId: "",
      introductionVideoUrl: "",
      staffRole: [staffRoleEnum.COMPANY_ADMIN],
      calendlyUrl: "",
      imageUrl: "",
      companyName: "",
      companyDomain: "",
      primaryColor: "#33FF99",
      secondaryColor: "#131C24",
      companyLogoURL: "",
      companyIconURL: "",
    },
    validationSchema: validationSchema[step],
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitStatus(true);
      // create card info on Stripe
      const cardId = await cardRef?.current?.triggerIntentSubmit();
      const [brokerFirstName, brokerLastName] = splitNameFromFullName(
        values.brokerName
      );
      const brokerDetailParams = {
        userDetails: {
          email: values.email,
          firstName: brokerFirstName,
          lastName: brokerLastName,
          state: "WAITING_VERIFICATION",
          phoneNumber: values.phoneNumber,
        },
        staffDetails: {
          introductionVideoUrl: values.introductionVideoUrl,
          staffRole: values.staffRole,
          imageUrl: values.imageUrl,
          calendlyUrl: values.calendlyUrl,
          brokerDetails: {
            connectiveAccountId: values.connectiveAccountId,
          },
        },
        companyDetails: {
          businessPhoneNumber: values.businessPhoneNumber,
          customerSupportEmail: values.customerSupportEmail,
          financialServicesLicenseNumber: values.financialServicesLicenseNumber,
          creditLicenseNumber: values.creditLicenseNumber,
          connectiveAccountId: values.connectiveAccountId,
          connectivePartnerId: values.connectivePartnerId,
          connectiveApiKey: values.connectiveApiKey,
          connectiveApiToken: values.connectiveApiToken,
          companyNumber: values.companyNumber || "",
          name: values.companyName,
          domain: values.companyDomain,
          themeDetails: {
            primaryColor: values.primaryColor,
            secondaryColor: values.secondaryColor,
            logoUrl: values.companyLogoURL,
            logoDarkUrl: values.companyLogoDarkURL,
            iconUrl: values.companyIconURL,
            iconDarkUrl: values.companyIconDarkURL,
          },
        },
        planCode,
        cardId,
      };
      await handleCreateBroker(brokerDetailParams);
    },
  });

  useEffect(() => {
    if (step === 1 && !cardElement) {
      setIsError(true);
      return;
    }
    if (Object.keys(formik.errors).length === 0) {
      setIsError(false);
    } else setIsError(true);
  }, [cardElement, formik.errors, step]);

  useEffect(() => {
    if (isSuccess) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    formik.setFieldValue("staffRole", [staffRoleEnum.COMPANY_ADMIN, staffRole]);
    formik.setFieldValue("creditLicenseNumber", "");
    formik.setFieldValue("financialServicesLicenseNumber", "");
    formik.setFieldValue("connectiveAccountId", "");
    formik.setFieldValue("connectivePartnerId", "");
    formik.setFieldValue("connectiveApiKey", "");
    formik.setFieldValue("connectiveApiToken", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffRole]);

  return (
    <div className="staff-signup__wrapper">
      <div className="staff-signup">
        <div
          onClick={() =>
            window.location.replace(process.env.GATSBY_WEALTHX_HOMEPAGE)
          }
          style={{ cursor: "pointer" }}
        >
          <img src={WealthxLogoDark} alt="wealthx-logo" />
        </div>
        {step !== 4 && (
          <>
            <div className="staff-signup__heading">
              Let&apos;s get you setup!
            </div>
            <CustomStepper activeStep={step} />
          </>
        )}
        <Formik initialValues={[]}>
          {({ setFieldError }) => (
            <Form>
              <>
                <div style={{ display: step === 0 ? "block" : "none" }}>
                  <PersonalDetails
                    refs={ServicesRef}
                    formik={formik}
                    staffRole={staffRole}
                  />
                </div>
                <div style={{ display: step === 1 ? "block" : "none" }}>
                  <CardInfo
                    email={confirmedEmail}
                    handleElement={handStripeElement}
                    ref={cardRef}
                  />
                </div>
                <div style={{ display: step === 2 ? "block" : "none" }}>
                  <CompanyInfo
                    refs={ServicesRef}
                    formik={formik}
                    staffRole={staffRole}
                  />
                </div>
                <div style={{ display: step === 3 ? "block" : "none" }}>
                  <BrandingInfo
                    refs={ServicesRef}
                    formik={formik}
                    staffRole={staffRole}
                  />
                </div>
                <div style={{ display: step === 4 ? "block" : "none" }}>
                  <SuccessStep />
                </div>
              </>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Button
                  onClick={handlePrev}
                  disabled={step === 0 || isError}
                  sx={{ display: step === 4 ? "none" : "block" }}
                  className="staff-signup__launch-button"
                >
                  Previous
                </Button>
                {step === steps.length - 1 ? (
                  <CustomButton
                    isLoading={submitStatus}
                    className="staff-signup__launch-button"
                    label="Finish"
                    onKeyPress={() => {}}
                    onClick={formik.handleSubmit}
                    sx={{ display: step === 4 ? "none" : "block" }}
                  />
                ) : (
                  <Button
                    onClick={() => {
                      handleNextStep(formik, setFieldError);
                    }}
                    disabled={isError}
                    sx={{ display: step === 4 ? "none" : "block" }}
                    className="staff-signup__launch-button"
                  >
                    Next
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SignupBanner />
    </div>
  );
};

Signup.propTypes = {};

export default Signup;
