import React, { useEffect, useState, forwardRef } from "react";
import { CircularProgress } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { usePaymentIntent } from "../../../hooks/billing.hooks";
import CheckoutForm from "../../account/billing-plan/checkout-form";
import { stripePromise } from "../../../utils/stripe";

const loadingSpinner = <CircularProgress color="inherit" size={24} />;

const CardInfo = forwardRef((props, ref) => {
  const { email, handleElement } = props;
  const [formId, setFormId] = useState(null);
  const { data } = usePaymentIntent(email);
  const [clientSecret, setClientSecret] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (data?.paymentIntentSecret && !clientSecret) {
      setClientSecret(data?.paymentIntentSecret);
    }
  }, [data?.paymentIntentSecret, clientSecret]);

  useEffect(() => {
    if (data?.customerId && !customerId) {
      setCustomerId(data?.customerId);
    }
  }, [data?.customerId, customerId]);

  return (
    <div>
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance: {
              theme: "stripe",
            },
            loader: "always",
          }}
          id={formId}
        >
          <CheckoutForm
            customerEmail={email}
            handleElement={handleElement}
            setFormId={setFormId}
            ref={ref}
          />
        </Elements>
      ) : (
        loadingSpinner
      )}
    </div>
  );
});

CardInfo.displayName = "CardInfo";

export default CardInfo;
