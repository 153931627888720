import React from "react";
import Box from "@mui/material/Box";
import { Stepper, Step, StepLabel, StepConnector } from "@mui/material";
import "./staff-signup.scss";

const steps = ["Personal Details", "Card", "Company", "Branding"];

const CustomStepper = ({ activeStep }) => {
  return (
    <Box className="custom-stepper__container" sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        connector={<StepConnector />}
        className="custom-stepper"
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
