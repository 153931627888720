import CallIcon from "@mui/icons-material/Call";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";

const SuccessStep = () => {
  return (
    <div className="congratulations-container">
      <h1>Congratulations</h1>
      <p>
        You will receive an email on next steps and we will contact you for
        final approval.
      </p>

      <h3>Contact us anytime if you have any concerns.</h3>

      <div className="contact-info">
        <div className="contact-item">
          <CallIcon className="icon" />
          <span>0416 338 438</span>
        </div>
        <div className="contact-item">
          <CalendarTodayIcon className="icon" />
          <span>Book call back</span>
        </div>
        <div className="contact-item">
          <EmailIcon className="icon" />
          <span>clint@wealthx.au</span>
        </div>
      </div>
    </div>
  );
};

export default SuccessStep;
