import { generateFieldList } from "../signup-common";
import "../staff-signup.scss";

const DEFAULT_COMPANY_ADMIN_OTHER_DETAIL = [
  { title: "Calendly Url", type: "calendlyUrl" },
  {
    title: "Video Url (Youtube, Vimeo, Wistia)",
    type: "introductionVideoUrl",
  },
  {
    inputType: "image",
    types: [
      {
        title: "Upload professional photo (800x450)",
        type: "imageUrl",
        aspect: 1.5,
      },
    ],
  },
];

const COMPANY_BRANDING = [
  {
    title: "Color Code Primary",
    inputType: "colorPicker",
    type: "primaryColor",
  },
  {
    title: "Color Code Secondary",
    inputType: "colorPicker",
    type: "secondaryColor",
  },
  {
    inputType: "image",
    types: [
      {
        title: "Upload Logo Dark(150x100)",
        type: "companyLogoDarkURL",
        aspect: 1.5,
      },
      {
        title: "Upload Logo Light (150x100)",
        type: "companyLogoURL",
        aspect: 1.5,
      },
    ],
  },
  {
    inputType: "image",
    types: [
      {
        title: "Upload Icon Dark(50x50)",
        type: "companyIconDarkURL",
        aspect: 1,
      },
      { title: "Upload Icon Light (50x50)", type: "companyIconURL", aspect: 1 },
    ],
  },
];

const BrandingInfo = ({ refs, formik, staffRole }) => {
  const generateOtherDetailList = () => {
    return generateFieldList(DEFAULT_COMPANY_ADMIN_OTHER_DETAIL, formik);
  };

  const generateCompanyBrand = () => {
    return generateFieldList(COMPANY_BRANDING, formik);
  };

  return (
    <form ref={refs} className="add-staff__form" onSubmit={() => {}}>
      <div className="add-staff__form-container">
        <div className="add-staff__title">Admin Advisor Content</div>
        {generateOtherDetailList(staffRole)}
      </div>
      <div className="add-staff__form-container">
        <div className="add-staff__title">Company Branding</div>
        {generateCompanyBrand()}
      </div>
    </form>
  );
};

export default BrandingInfo;
