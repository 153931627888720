import { STAFF_ROLE } from "../../../commons/enum";
import { staffRoleEnum } from "../staff-sign-up.enum.ts";
import "../staff-signup.scss";
import { generateFieldList } from "../signup-common";

const COMPANY_DETAILS = [
  {
    title: "Name",
    type: "companyName",
  },
  { title: "ACN (Australian Company Number)", type: "companyNumber" },
  {
    title: "Customer Support Email",
    fieldType: "email",
    type: "customerSupportEmail",
  },
  { title: "Main Business Phone Number ", type: "businessPhoneNumber" },
  {
    title: "Domain",
    inputType: "domainName",
    type: "companyDomain",
  },
];

const CONNECTIVE_DETAILS = [
  { title: "CA Number", type: "connectiveAccountId" },
  {
    title: "Company Id",
    type: "connectivePartnerId",
  },
  { title: "API Key", type: "connectiveApiKey" },
  {
    title: "API Token",
    type: "connectiveApiToken",
  },
];

const CompanyInfo = ({ refs, formik, staffRole }) => {
  const getCompanyDetailsFieldByRole = (role) => {
    const companyDetailsList = [...COMPANY_DETAILS];
    switch (role) {
      case staffRoleEnum.BROKER:
        companyDetailsList.splice(2, 0, {
          title: "Australian Credit License Number",
          type: "creditLicenseNumber",
        });
        return companyDetailsList;
      default:
        companyDetailsList.splice(2, 0, {
          title: "Australian Financial Services License Number",
          type: "financialServicesLicenseNumber",
        });
        return companyDetailsList;
    }
  };

  const generateCompanyDetail = (role) => {
    const fieldList = getCompanyDetailsFieldByRole(role);
    return generateFieldList(fieldList, formik);
  };

  const generateConnectiveDetailList = () => {
    return generateFieldList(CONNECTIVE_DETAILS, formik);
  };

  return (
    <form ref={refs} className="add-staff__form" onSubmit={() => {}}>
      <div className="add-staff__form-container">
        {staffRole === STAFF_ROLE.BROKER && (
          <>
            <div className="add-staff__title">Connective Details</div>
            {generateConnectiveDetailList()}
          </>
        )}
      </div>
      <div className="add-staff__form-container">
        <div className="add-staff__title">Company Details</div>
        {generateCompanyDetail(staffRole)}
      </div>
    </form>
  );
};

export default CompanyInfo;
