import { generateFieldList } from "../signup-common";
import "../staff-signup.scss";

const COMPANY_ADMIN_DETAILS = [
  {
    title: "Email Address",
    fieldType: "email",
    type: "email",
  },
  {
    title: "Name",
    type: "brokerName",
  },
  {
    title: "Phone Number",
    type: "phoneNumber",
  },
];

const PersonalDetails = ({ refs, formik }) => {
  const generatDetailList = () => {
    return generateFieldList(COMPANY_ADMIN_DETAILS, formik);
  };

  return (
    <form ref={refs} className="add-staff__form" onSubmit={() => {}}>
      <div className="add-staff__form-container">
        {/* <div className="add-staff__title">Admin Company Details</div> */}
        {generatDetailList()}
      </div>
    </form>
  );
};

export default PersonalDetails;
